
/*
Import font awesome icons
*/
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faAllergies,
  faAngleDown,
  faAngleLeft,
  faAngleRight,
  faArrowLeft,
  faArrowRight,
  faBarcode,
  faBars,
  faBell,
  faBookOpen,
  faChalkboardTeacher,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faCircle,
  faClock,
  faCode,
  faCog,
  faCogs,
  faCopy,
  faCut,
  faDiceD20,
  faDownload,
  faEdit,
  faEraser,
  faExternalLinkAlt,
  faEye,
  faFileMedical,
  faFilePdf,
  faFilePrescription,
  faFilter,
  faFlask,
  faFolderPlus,
  faGlobe,
  faGraduationCap,
  faHammer,
  faHandHoldingMedical,
  faHeartbeat,
  faHospital,
  faHourglassEnd,
  faHourglassStart,
  faInfoCircle,
  faList,
  faLock,
  faMagic,
  faMedkit,
  faMinus,
  faNotesMedical,
  faPen,
  faPlus,
  faPrescription,
  faPrint,
  faQuestion,
  faSearch,
  faSchool,
  faSignInAlt,
  faSignOutAlt,
  faStethoscope,
  faStickyNote,
  faStopwatch,
  faStopCircle,
  faSort,
  faSortUp,
  faSortDown,
  faTable,
  faTag,
  faThLarge,
  faTimes,
  faTimesCircle,
  faTrash,
  faUndo,
  faUnlock,
  faUpload,
  faUser,
  faUserGraduate,
  faUserClock,
  faUserFriends,
  faPauseCircle,
  faPlayCircle,
  faFastForward,
  faBackward,
  faUserInjured,
  faVial, faVials, faMobile
} from '@fortawesome/free-solid-svg-icons'
import { faFontAwesome, faCanadianMapleLeaf } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faBookMedical } from '@fortawesome/free-solid-svg-icons/faBookMedical'

export default function (Vue) {
  library.add(
    faAllergies,
    faAngleDown,
    faAngleLeft,
    faAngleRight,
    faArrowLeft,
    faArrowRight,
    faBars,
    faBarcode,
    faBell,
    faBookMedical,
    faBookOpen,
    faChalkboardTeacher,
    faCheck,
    faCheckCircle,
    faChevronDown,
    faChevronUp,
    faCircle,
    faClock,
    faCode,
    faCog,
    faCogs,
    faCopy,
    faCut,
    faDiceD20,
    faDownload,
    faEdit,
    faExternalLinkAlt,
    faEraser,
    faEye,
    faFileMedical,
    faFilePrescription,
    faFilePdf,
    faFilter,
    faFolderPlus,
    faFlask,
    faEraser,
    faGlobe,
    faGraduationCap,
    faHammer,
    faHandHoldingMedical,
    faMobile,
    faHeartbeat,
    faHospital,
    faHourglassEnd,
    faHourglassStart,
    faInfoCircle,
    faList,
    faLock,
    faMagic,
    faSearch,
    faMedkit,
    faMinus,
    faNotesMedical,
    faPen,
    faPlus,
    faPrescription,
    faPrint,
    faNotesMedical,
    faQuestion,
    faSchool,
    faSignInAlt,
    faSignOutAlt,
    faStethoscope,
    faStickyNote,
    faStopwatch,
    faStopCircle,
    faSort,
    faSortUp,
    faSortDown,
    faTable,
    faTag,
    faThLarge,
    faTimes,
    faTimesCircle,
    faTrash,
    faUndo,
    faUnlock,
    faUpload,
    faUser,
    faUserGraduate,
    faUserClock,
    faUserFriends,
    faUserInjured,
    faPauseCircle,
    faPlayCircle,
    faFastForward,
    faBackward,
    faVial, faVials
  )
  library.add(
    faFontAwesome,
    faCanadianMapleLeaf
  )
  // IN CODE sample usage is:
  // fas-icon(class="fa", icon="graduation-cap")
  Vue.component('fas-icon', FontAwesomeIcon)
}
