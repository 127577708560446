<template lang="pug">
  div
    app-dialog(
      :isModal="false",
      small=false,
      ref="theDialog",
      :useSave="false",
      @cancel="cancelDialog",
      :cancelButtonLabel='ehrText.closeButtonLabel',
      :zIndexBase=10000
    )
      h2(slot="header") {{ehrText.scanDialogTitle}}
      div(slot="body")
        div.panel
          div.upper-panel
            div(v-if='isConnectForCurrentUser')
              h2 {{ehrText.scanDialogTitle}}
              .message-panel
                div(class="message-area")
                  div(v-text-to-html='toHtml(ehrText.scanDialogConnectedMessage)')
                div(class="qr-area")
                  ui-button(v-on:buttonClicked="disconnectUser") {{ ehrText.scanDisconnect }}
            div(v-else)
              h2 {{ehrText.scanDialogUnconnectedTitle}}
              .message-panel
                div(class="message-area")
                  div(v-text-to-html='toHtml(ehrText.scanDialogUnconnectedMessage)')
                div(class="qr-area")
                  qrcode-vue(:value="linkValue", size="200", level="H", margin="5")
                  .linkValue(v-if="isDev")
                    a(:href='linkValue', target="_blank") DevTest
          div.lower-panel
            h5 {{ ehrText.scanEventConsole }}
            .logContainer(ref="logContainer")
              div.log-entry(v-for="(log, index) in scanLogs" :key="index")
                span.logTimestamp {{ log.timestamp }}
                span.logMessage {{ log.message }}

</template>

<script>
import AppDialog from '@/app/components/AppDialogShell'
import { prepareMultilineHypertext, t18EhrText } from '@/helpers/ehr-t18'
import UiButton from '@/app/ui/UiButton.vue'
import StoreHelper from '@/helpers/store-helper'
import QrcodeVue from 'qrcode.vue'

const LOCALHOST = window.location.origin.includes('localhost')
export default {
  components: {
    QrcodeVue,
    UiButton,
    AppDialog
  },
  computed: {
    isDev () { return LOCALHOST },
    ehrText () { return t18EhrText() },
    connectedUser () { return this.$store.getters['scanAppStore/connectedUser'] },
    isConnectForCurrentUser () { return !!this.connectedUser  },
    scanLogs () { return this.$store.getters['scanAppStore/scanLogs']},
    linkValue () {
      let url = StoreHelper.apiUrlGet()
      const userId = this.$store.getters['userStore/userId']
      return url + '/scan-app/scan/' + userId
    },
  },
  methods: {
    toHtml (text) { return prepareMultilineHypertext(text) },
    showDialog: function () {
      this.$refs.theDialog.onOpen()
      this.scrollLogs()
    },
    scrollLogs () {
      this.$nextTick(() => {
        const container = this.$refs.logContainer
        if (container) {
          container.scrollTop = container.scrollHeight
        }
      })
    },
    disconnectUser () {
      this.$store.dispatch('scanAppStore/setConnectedUser', undefined)
    },
    cancelDialog: function () {
      this.$refs.theDialog.onClose()
    },
  },
  watch: {
    scanLogs () {
      this.scrollLogs()
    }
  }

}
</script>

<style lang='scss' scoped>
.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.upper-panel {
  flex: 0 0 auto;
  padding: 8px;
  //background: #f0f0f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-panel {
  flex: 0 0 auto;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.qr-area {
  margin-left: 2rem;
  width: 30%;
}
.message-area {
  width: 100%;
}
.lower-panel {
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 8px;
  background: #ffffff;
  border-top: 1px solid #ccc;
}

.logContainer {
  height: 10rem;
  flex: 1 1 auto;
  overflow-y: auto;
  padding: 8px;
  background: #1e1e1e; /* Dark background like dev tools */
  color: #d4d4d4; /* Default text color */
  font-family: Consolas, 'Courier New', monospace; /* Monospace font */
  line-height: 1.4;
}
.log-entry {
  display: flex;
  align-items: center;
  padding-bottom: 4px;
  border-bottom: 1px solid #3c3c3c;
  font-family: Consolas, 'Courier New', monospace;
  color: #d4d4d4;
}

.log-entry:last-child {
  border-bottom: none;
}

.logTimestamp {
  width: 10rem; /* Fixed width for alignment */
  text-align: right;
  margin-right: 2rem;
}

.logMessage {
  flex: 1; /* Allow the message to take up remaining space */
  font-weight: bold;
  white-space: pre-wrap; /* Preserve whitespace for multiline messages */
}

.log-entry.error .message {
  color: #f44747;
}
</style>
