export const APP_ICONS = {
  activity: 'notes-medical',
  activityClose: 'hourglass-end',
  activityOpen: 'hourglass-start',
  barcode: 'barcode',
  classList: 'user-friends',
  code: 'code',
  configure: 'cogs',
  copy: 'copy',
  consumer: 'school',
  course: 'chalkboard-teacher',
  download: 'download',
  edit: 'edit',
  ehrPage: 'book-medical',
  eraser: 'eraser',
  exitToLms: 'sign-out-alt',
  externalLink: 'external-link-alt',
  file: 'file-medical',
  filter: 'filter',
  forceSubmit: 'lock',
  itemDetails: 'cog',
  retractSubmit: 'unlock',
  list: 'list',
  lobj: 'book-open',
  menu: 'bars',
  new: 'magic',
  save: 'check',
  signIn: 'sign-in-alt',
  signOut: 'sign-out-alt',
  seed: 'hand-holding-medical',
  search: 'search',
  returnToSeedList: 'list',
  rawEhr: 'th-large',
  question: 'question',
  sortAsc: 'sort-down',
  sortDesc: 'sort-up',
  sortNone: 'sort',
  stopwatch: 'stopwatch',
  stopCircle: 'stop-circle',
  student: 'user-graduate',
  table: 'table',
  tags: 'tag', // for filtering by tag applied to an object
  trash: 'trash',
  undo: 'undo',
  upload: 'upload',
  view: 'eye',

}
