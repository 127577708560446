import store from '@/store'
import Vue from 'vue'
import EventBus, {  CODE_SCAN_EVENT } from '@/helpers/event-bus'

class ScanAppHelperInner {

  /**
   * @param userId
   * @returns {Promise<void>}
   */
  async scanConnect (userId) {
    console.log('scanConnect  userId', userId)
    if (!this.validateUser(userId)) {
      // silently do nothing if the message is for any user other than the authenticated user.
      console.log('scanConnect  message for another user. Do nothing here.')
      return
    }
    let event = 'Your mobile is connected and ready to scan.'
    console.log('scanConnect. User has connected with their mobile.', userId)
    await store.dispatch('scanAppStore/setConnectedUser', userId)
    await this.appendLog(event)
  }

  async scanCode (userId, code) {
    if (!this.validateUser(userId)) {
      return
    }
    updateFocusedTextInput(code)
    const event = 'Scan code: "' + code + '"'
    await this.appendLog(event)
  }

  async appendLog (event) {
    await store.dispatch('scanAppStore/appendLog', event)
  }

  validateUser (userId ) {
    const thisClientUser = store.getters['userStore/userId']
    if (thisClientUser !== userId) {
      console.log('Scan message is for user:', userId , 'not this user:', thisClientUser)
    }
    return thisClientUser === userId
  }
}
function updateFocusedTextInput (newText) {
  Vue.nextTick(() => {
    const focusedElement = document.activeElement
    if (focusedElement &&
      focusedElement.tagName === 'INPUT' &&
      focusedElement.type === 'text') {
      // Set the value and simulate a "carriage return"
      focusedElement.value = newText // + "\n";
      // Find all focusable elements and advance focus
      const focusableElements = Array.from(
        document.querySelectorAll('input, button, select, textarea, a[href], [tabindex]:not([tabindex="-1"])')
      )
      const currentIndex = focusableElements.indexOf(focusedElement)
      const nextElement = focusableElements[currentIndex + 1]
      if (nextElement) {
        nextElement.focus()
      }
      // The event fires and is handled async.
      // in the payload provide the current focused element
      // also provide the new text so the handler can use this if needed.
      // See MarBarCodeDialog for example usage.
      EventBus.$emit(CODE_SCAN_EVENT, {newText, focusedElement})
    }
  })
}


const ScanAppHelper = new ScanAppHelperInner()
export default ScanAppHelper
