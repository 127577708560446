
const state = {
  scannedCode: undefined,
  connectedUser: undefined,
  scanLogs: []
}

const getters = {
  scanLogs: state => state.scanLogs,
  connectedUser: state => state.connectedUser
}

const actions = {
  appendLog (context, event) {
    context.commit('_appendLog', event)
  },

  setConnectedUser (context, userId) {
    context.commit('_setConnectedUser', userId)
  }
}

const mutations = {
  _appendLog: (state, event) => {
    const log = {
      timestamp: new Date().toLocaleTimeString(),
      message: event
    }
    state.scanLogs.push(log)
    state.scanLogs = [...state.scanLogs]
  },
  _setConnectedUser: (state, userId) => {
    state.connectedUser = userId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
